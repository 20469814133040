import axiosClient from "../../config/axiosConfig";
import { useContext, useReducer } from "react";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";
import { DELETE_PHONE, POST_ORDER_ADDRESS, POST_USERLOGGED, POST_USERNOT, REMOVE_USER, } from "../../type";
import AddressContext from "../Address/AddressContext";

const UserState = ({children}) => {
  const {deleteAddressArray} = useContext(AddressContext);

  const initialState = {
    userLogged: {},
    auth: false,
    deliveryAddress: {},
    checkedUser: false,
    googleUser: undefined,
    orderStateNot: undefined,
    promNot: undefined
  }

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const login = async (data) => {
    let errors = {};
    try {
      const response = await axiosClient.post('/users', data);
      if(response.status === 200){
        localStorage.setItem('ID', response.data.id);
        axiosClient.defaults.headers.common['x-auth-token'] = response.data.id;
        dispatch({
          type: POST_USERLOGGED,
          payload: response.data
        });
        return errors;
      }else if (response.status === 201){
        errors.data = 'Datos Incorrectos';
        dispatch({
          type: REMOVE_USER
        });
        return errors;
      }
    } catch (error) {
      console.log(error);
      errors.fail = 'Error en la petición, inténtelo nuevamente';
      dispatch({
        type: REMOVE_USER
      });
      return errors;
    }
  }

  const logout = () => {
    dispatch({
      type: REMOVE_USER
    })
    deleteAddressArray();
    window.location.assign(process.env.REACT_APP_ORIGIN);
  }

  const getAuth = async () => {
    const token = localStorage.getItem('ID');
    if(token){
      axiosClient.defaults.headers.common['x-auth-token'] = token;
      try {
        const response = await axiosClient.get('/users');
        if(response.status === 200){
          if(response.data === null){
            dispatch({
              type: REMOVE_USER
            })
          }else{
            dispatch({
              type: POST_USERLOGGED,
              payload: response.data
            })
          }
        }
      } catch (error) {
        dispatch({
          type: REMOVE_USER
        })
      }
    }else{
      dispatch({
        type: REMOVE_USER
      })
    }
  }
  
  const postOrderAddress = async (orderAddress, id) => {
    let errors = {};
    try {
      const response = await axiosClient.post('/users/address', {address: id});
      if(response.status === 200){
        dispatch({
          type: POST_ORDER_ADDRESS,
          payload: orderAddress
        })
      }
      return errors;
    } catch (error) {
      errors.servidor = 'Error al definir la dirección de envío, intentelo nuevamente'
      return errors;
    }
  }

  const setNewPassword = async (data) => {
    let errors = {};
    try {
      const response = await axiosClient.post('/users/new-password/user', data);
      if(response.status === 201){
        errors.data = 'Datos incorrectos';
        return errors;
      }
      return errors;
    } catch (error) {
      errors.servidor = 'Error, inténtelo nuevamente';
      return errors;
    }
  }

  const postPhoneNot = async (data) => {
    let errors = {};
    try {
      const response = await axiosClient.post('/users/user-notifications', data);
      if(response.status === 201){
        errors.user = 'Error, inténtelo nuevamente o comuniquese con nosotros'
        return errors;
      }else if(response.status === 200){
        dispatch({
          type: POST_USERNOT,
          payload: data
        })
      }
      return errors;
    } catch (error) {
      errors.user = 'Error, inténtelo nuevamente o comuniquese con nosotros'
      return errors
    }
  }

  const deletePhoneNumber = async () => {
    let errors = {};
    try {
      const response = await axiosClient.post('/users/deletephone');
      if(response.status === 200){
        dispatch({
          type: DELETE_PHONE,
        })
      }else if (response.status === 201){
        errors.user = 'Error, inténtelo nuevamente o comuniquese con nosotros'
        return errors;
      }
      return errors;
    } catch (error) {
      errors.user = 'Error, inténtelo nuevamente o comuniquese con nosotros'
      return errors;
    }
  }

  return (
    <UserContext.Provider value={{
      userLogged: state.userLogged,
      auth: state.auth,
      deliveryAddress: state.deliveryAddress,
      checkedUser: state.checkedUser,
      googleUser: state.googleUser,
      orderStateNot: state.orderStateNot,
      promNot: state.promNot,
      postOrderAddress,
      login,
      logout,
      getAuth,
      setNewPassword,
      postPhoneNot,
      deletePhoneNumber
    }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserState;