import { DELETE_ADDRESS, DELETE_ADDRESS_ARRAY, GET_ADDRESS, POST_ADDRESS } from "../../type";

export default (state, action) => {
  switch(action.type){
    case GET_ADDRESS:
      return {
        ...state,
        address: action.payload
      }
    case POST_ADDRESS:
      return {
        ...state,
        address: state.address.concat(action.payload)
      }
    case DELETE_ADDRESS:
      return {
        ...state,
        address: action.payload
      }
    case DELETE_ADDRESS_ARRAY:
      return {
        ...state,
        address: action.payload
      }
  }
}