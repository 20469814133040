import { useReducer } from "react";
import axiosClient from "../../config/axiosConfig";
import { DELETE_PRODUCT, EDIT_PRODUCT, GET_ALL_PRODUCTS, POST_PRODUCT } from "../../type";
import ProductsContext from "./ProductsContext";
import ProductsReducer from "./ProductsReducer";

const ProductsState = ({ children }) => {
  const initialState = {
    products: []
  }

  const [state, dispatch] = useReducer(ProductsReducer, initialState);

  const getAllProducts = async () => {
    let errors = {}
    try {
      const response = await axiosClient.get('/products');
      dispatch({
        type: GET_ALL_PRODUCTS,
        payload: response.data
      })
      return errors;
    } catch (error) {
      errors.servidor = 'Error al cargar los productos, inténtelo nuevamente recargando la página'
      return errors;
    }
  }

  const postProducts = async (products) => {
    let errors = {};
    for(let i = 0; i < products.length; i++){
      try {
        const response = await axiosClient.post('/products', products[i])
        if(response.status === 201){
          errors[`${products[i].productName}`] = `El producto ${products[i].productName} ya se encuentra en la base de datos`
        }else if(response.status === 200){
          dispatch({
            type: POST_PRODUCT,
            payload: [response.data.product]
          })
        }
      } catch (error) {
        errors[`${products[i].productName}`] = `Error al cargar el producto ${products[i].productName}`
      }
    }
    return errors;
  }

  const deleteProduct = async (product) => {
    let errors = {};
    const newProductsArray = state.products.filter(item => item !== product);
    try {
      await axiosClient.delete(`/products/${product._id}`);
      dispatch({
        type: DELETE_PRODUCT,
        payload: newProductsArray
      })
      return errors;
    } catch (error) {
      errors.servidor = 'Error al eliminar el producto, inténtelo nuevamente';
      return errors;
    }
  }

  const editProducts = async (dataArray) => {
    let errors = {};
    for(let i = 0; i < dataArray.length; i++){
      try {
        const response = await axiosClient.post('/products/edit', dataArray[i]);
        if(response.status === 200){
          dispatch({
            type: EDIT_PRODUCT,
            payload: dataArray[i]
          })
        }
      } catch (error) {
        errors[`${dataArray[i].productName}`] = `Error al editar el producto ${dataArray[i].productName}`
      }
    }
    return errors;
  }

  return (
    <ProductsContext.Provider value={{
      products: state.products,
      getAllProducts,
      postProducts,
      deleteProduct,
      editProducts
    }}>
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsState;
