import React from 'react';
import MaintenancePage from '../components/MaintenancePage/MaintenancePage';

const Maintenance = () => {
  return (
    <>
      <MaintenancePage />
    </>
  );
};

export default Maintenance;