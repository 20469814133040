import React, { useReducer } from 'react';
import axiosClient from '../../config/axiosConfig';
import { DELETE_ADDRESS, DELETE_ADDRESS_ARRAY, GET_ADDRESS, POST_ADDRESS } from '../../type';
import AddressContext from './AddressContext';
import AddressReducer from './AddressReducer';

const AddressState = ({children}) => {
  const initialState = {
    address: []
  };

  const [state, dispatch] = useReducer(AddressReducer, initialState);

  const getUserAddress = async () => {
    let errors = {};
    try {
      const response = await axiosClient.get(`/address`);
      dispatch({
        type: GET_ADDRESS,
        payload: response.data
      })
      return errors;
    } catch (error) {
      errors.servidor = 'Error al traer las direcciones del servidor, actualice la página'
      return errors;
    }
  }

  const postUserAddress = async (data) => {
    let errors = {};
    try {
      const response = await axiosClient.post('/address', data);
      if(response.status === 200){
        dispatch({
          type: POST_ADDRESS,
          payload: [data]
        })
        return [errors, response.data._id]
      }
      return [errors];
    } catch (error) {
      errors.servidor = 'Error al almacenar la dirección, inténtelo nuevamente'
      return [errors];
    }
  }

  const deleteAddress = async (id) => {
    let errors = {};
    const newAddressArray = state.address.filter(item => item._id !== id)
    try {
      const response = await axiosClient.delete(`/address/${id}`);
      if(response.status === 200){
        dispatch({
          type: DELETE_ADDRESS,
          payload: newAddressArray
        })
      }
      return errors;
    } catch (error) {
      errors.servidor = 'Error al eliminar la dirección, inténtelo nuevamente';
      return errors;
    }
  }

  const deleteAddressArray = () => {
    dispatch({
      type: DELETE_ADDRESS_ARRAY,
      payload: [] 
    })
  }

  return (
    <AddressContext.Provider value={{
      address: state.address,
      getUserAddress,
      postUserAddress,
      deleteAddress,
      deleteAddressArray
    }}>
      {children}
    </AddressContext.Provider>
  );
};

export default AddressState;