import { EDIT_CART, GET_CART, POST_CART, POST_OLD_CART } from "../../type";


export default (state, action) => {
  switch (action.type){
    case GET_CART:
      return {
        ...state,
        cart: action.payload
      }
    case POST_CART:
      localStorage.setItem('CART', JSON.stringify(action.payload));
      return {
        ...state,
        cart: action.payload
      }
    case EDIT_CART:
      const newCart = state.cart.concat([]);
      const item = newCart.find(product => product.id === action.payload.id);
      const index = newCart.indexOf(item);
      newCart[index].quantity = action.payload.quantity;
      localStorage.setItem('CART', JSON.stringify(newCart));
      return {
        ...state,
        cart: newCart
      }
    case POST_OLD_CART:
      localStorage.setItem('CART', JSON.stringify(action.payload));
      return {
        ...state,
        cart: action.payload
      }
  }
}