import './Error404.css';
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../constants';

const Error404 = () => {
  return (
    <div className='error-page'>
      <div className='error-style p-3'>
        <img src={IMAGE_URL+'not-products/404-image.webp'} alt="404-img" width={300} />
        <h1 className='fw-bold mt-1 display-1'>404</h1>
        <h4 className='fw-bold border-bottom border-danger py-3'>Error - Page Not Found</h4>
        <p className='fw-bold'>Please check the URL.</p>
        <p className='text-center'>Otherwise, press the link below to be redirected to the homepage.</p>
        <Link to='/' className='fs-4 fw-bold'>Redirect-me</Link>
      </div>
    </div>
  );
};

export default Error404;