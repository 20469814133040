export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const POST_PRODUCT = 'POST_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';

export const GET_CART = 'GET_CART';
export const POST_CART = 'POST_CART';
export const EDIT_CART = 'EDIT_CART';
export const POST_OLD_CART = 'POST_OLD_CART';

export const POST_USERLOGGED = 'POST_USERLOGGED';
export const POST_ORDER_ADDRESS = 'POST_ORDER_ADDRESS';
export const REMOVE_USER = 'REMOVE_USER';
export const POST_USERNOT = 'POST_USERNOT';
export const DELETE_PHONE = 'DELETE_PHONE';

export const GET_ADDRESS = 'GET_ADDRESS';
export const POST_ADDRESS = 'POST_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_ARRAY = 'DELETE_ADDRESS_ARRAY';
