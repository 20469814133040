import useMediaQuery from '../../hooks/useMediaQuery';
import './MaintenancePage.css';

const MaintenancePage = () => {
  const {height} = useMediaQuery()

  return (
    <div className='maintenance-style' style={{minHeight:height + 'px'}}>
      {/* <div className='brand-position'>
        <img width={60} src="https://storage.googleapis.com/marketar_bucket/not-products/market-png.webp" alt="" />
        <span className="mb-5 fs-4 text-decoration-underline brand-style">MARKET</span>
      </div> */}
      <img className='img-size' src="https://storage.googleapis.com/marketar_bucket/not-products/website-under-maintenance-v4.gif" alt="" />
      <div className='my-4 fs-3 text-center px-3'>Estamos ultimando detalles antes de su lanzamiento</div>
      <img className='rounded-circle' width={150} src="https://storage.googleapis.com/marketar_bucket/not-products/rocket.gif" alt="" />
    </div>
  );
};

export default MaintenancePage;