import React, { useContext, useReducer } from "react";
import { EDIT_CART, GET_CART, POST_CART, POST_OLD_CART } from "../../type";
import ProductsContext from "../Products/ProductsContext";
import CartContext from "./CartContext";
import CartReducer from "./CartReducer";

const CartState = ({ children }) => {
  const { products } = useContext(ProductsContext);

  const initialState = {
    cart: []
  };

  const [state, dispatch] = useReducer(CartReducer, initialState);

  const getCartProducts = async () => {
    const cartLS = JSON.parse(localStorage.getItem('CART'));
    if(cartLS){
      if(!cartLS[0]?.unit){
        localStorage.removeItem('CART')
      }else{
        dispatch({
          type: GET_CART,
          payload: cartLS
        })
      }
    }
  }

  const postCartProduct = (product) => {
    const newCartProduct = state.cart.concat([product])
    dispatch({
      type: POST_CART,
      payload: newCartProduct
    })
  }

  const editCartProduct = (product) => {
    dispatch({
      type: EDIT_CART,
      payload: product
    })
  }

  const deleteCartProduct = (id) => {
    const newCart = state.cart.filter(item => item.id !== id);
    dispatch({
      type: POST_CART,
      payload: newCart
    })
  }

  const deleteAllCart = async () => {
    dispatch({
      type: POST_CART,
      payload: []
    })
  }

  const copyOrder = async (order) => {
    let newCart = [];
    let error = [];
    order.orderPlaced.forEach(item => {
      const productAct = products.find(product => product._id === item.id);
      const cartProduct = {
        id: item.id,
        price: productAct.price,
        productName: item.productName,
        unit: productAct.unit,
        category: item.category,
        quantity: item.quantity,
        delta: productAct.delta,
        minUnit: productAct.minUnit
      }
      if(item.unit !== productAct.unit){
        error.push(item.productName);
      }else{
        newCart.push(cartProduct)
      }
    })
    dispatch({
      type: POST_OLD_CART,
      payload: newCart
    })
    return error;
  }

  return (
    <CartContext.Provider value={{
      cart: state.cart,
      getCartProducts,
      postCartProduct,
      editCartProduct,
      deleteCartProduct,
      deleteAllCart,
      copyOrder
    }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartState;
