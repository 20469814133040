import { DELETE_PRODUCT, EDIT_PRODUCT, GET_ALL_PRODUCTS, POST_PRODUCT } from "../../type";


export default (state, action) => {
  switch(action.type){
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload.sort((a, b) => {
          if(a.productName < b.productName){
            return -1
          } else if(a.productName > b.productName){
            return 1
          }else {
            return 0
          }
        })
      }
    case DELETE_PRODUCT:
      return {
        ...state,
        products: action.payload
      }
    case POST_PRODUCT:
      return {
        ...state,
        products: state.products.concat(action.payload).sort((a, b) => {
          if(a.productName < b.productName){
            return -1
          } else if(a.productName > b.productName){
            return 1
          }else {
            return 0
          }
        })
      }
    case EDIT_PRODUCT:
      const item = state.products.find(item => item._id === action.payload._id);
      const index = state.products.indexOf(item);
      const newProduct = {
        ...item,
        price: action.payload.price,
        unit: action.payload.unit,
        minUnit: action.payload.minUnit,
        delta: action.payload.delta
      }
      state.products.splice(index, 1, newProduct);
      return {...state}
  }
}