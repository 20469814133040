import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
// import Layout from './components/Layout/Layout';
import AddressState from './context/Address/AddressState';
import CartState from './context/Cart/CartState';
import ProductsState from './context/Products/ProductsState';
import UserState from './context/Users/UserState';
// import Address from './pages/Address';
// import Admin from './pages/Admin';
// import AdminHome from './pages/AdminHome';
// import Home from './pages/Home';
import Maintenance from './pages/Maintenance';
// import Orders from './pages/Orders';
// import Products from './pages/Products';
// import RegisterPage from './pages/RegisterPage';
// import AdminRoute from './routes/AdminRoute';
// import PrivateRoute from './routes/PrivateRoute';
import Page404 from './pages/Page404';
// import NewUser from './pages/NewUser';
// import ForgottenUser from './pages/ForgottenUser';
// import NewPassword from './pages/NewPassword';
// import Account from './pages/Account';
// import Phone from './pages/Phone';
// import ManageOrders from './pages/ManageOrders';

function App() {
  return (
    <>
      <AddressState>
        <UserState>
          <ProductsState>
            <CartState>
              <Routes>
                <Route path='/' element={<Maintenance />} />
                <Route path='*' element={<Page404 />} />
                {/* <Route path='/' element={<Home />} />
                <Route path='/products/:id' element={<Products />} />
                <Route path='/register' element={<RegisterPage />} />
                <Route path='/adminHome' element={<AdminRoute><AdminHome /></AdminRoute>} />
                <Route path='/adminHome/:id' element={<AdminRoute><Admin /></AdminRoute>} />
                <Route path='/orders' element={<PrivateRoute><Orders /></PrivateRoute>} />
                <Route path='/userAddress' element={<PrivateRoute><Address /></PrivateRoute>} />
                <Route path='/new-user/:id' element={<NewUser />} />
                <Route path='/forgotten-user' element={<ForgottenUser />} />
                <Route path='/new-password/:id' element={<NewPassword />} />
                <Route path='/account' element={<PrivateRoute><Account /></PrivateRoute>} />
                <Route path='/account/phoneContact' element={<PrivateRoute><Phone /></PrivateRoute>} />
                <Route path='/management' element={<ManageOrders />} /> */}
              </Routes>
            </CartState>
          </ProductsState>
        </UserState>
      </AddressState>
    </>
  );
}

export default App;
