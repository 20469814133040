import axiosClient from "../../config/axiosConfig"
import { DELETE_PHONE, POST_ORDER_ADDRESS, POST_USERLOGGED, POST_USERNOT, REMOVE_USER } from "../../type"


export default (state, action) => {
  switch(action.type){
    case POST_USERLOGGED:
      if(action.payload.adminKey === undefined){
        return {
          ...state,
          userLogged: {
            userName: action.payload.userName,
            userEmail: action.payload.userEmail,
            userPhone: action.payload.userPhone
          },
          auth: true,
          deliveryAddress: action.payload.address,
          checkedUser: action.payload.checkedUser,
          googleUser: action.payload.googleUser,
          orderStateNot: action.payload.orderStateNot,
          promNot: action.payload.promNot
        }
      }else{
        return {
          ...state,
          userLogged: {
            userName: action.payload.userName,
            userEmail: action.payload.userEmail,
            userPhone: action.payload.userPhone,
            adminKey: action.payload.adminKey
          },
          auth: true,
          deliveryAddress: action.payload.address,
          checkedUser: action.payload.checkedUser,
          googleUser: action.payload.googleUser,
          orderStateNot: action.payload.orderStateNot,
          promNot: action.payload.promNot
        }
      }
    case POST_ORDER_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload
      }
    case REMOVE_USER:
      if(localStorage.getItem('ID')){
        localStorage.removeItem('ID')
      }
      delete axiosClient.defaults.headers.common['x-auth-token'];
      return {
        ...state,
        userLogged: {},
        auth: false,
        deliveryAddress: {},
        checkedUser: undefined,
        googleUser: undefined,
        orderStateNot: undefined,
        promNot: undefined    
      }
    case POST_USERNOT:
      return {
        ...state,
        userLogged: {
          ...state.userLogged,
          userPhone: action.payload.userPhone
        },
        orderStateNot: action.payload.orderStateNot,
        promNot: action.payload.promNot
      }
    case DELETE_PHONE: 
      return {
        ...state,
        userLogged: {
          ...state.userLogged,
          userPhone: undefined
        },
        orderStateNot: undefined,
        promNot: undefined
      }
  }
}